<template>
  <div class="light-gallery">
    <div v-for="i in [index]" :key="i">
      <img class="image" :src="currentImage" alt=""/>

      <button v-if="images.length > 1" class="next" @click="next()">
        <b-icon-chevron-right icon="chevron-right"></b-icon-chevron-right>
      </button>
      <button v-if="images.length > 1" class="prev" @click="prev()">
        <b-icon-chevron-left icon="chevron-left"></b-icon-chevron-left>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'light-gallery',
  props: {
    images: Array
  },
  data () {
    return {
      index: 0,
    }
  },
  computed: {
    currentImage () {
      return process.env.VUE_APP_API_BASE_URL + '/img/' + this.images[Math.abs(this.index) % this.images.length].file_name
    }
  },
  methods: {
    next () {
      this.index += 1
    },
    prev () {
      this.index -= 1
    }
  }
}
</script>

<style lang="scss" scoped>
.light-gallery {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .image {
    height: auto;
    width: auto;
    max-height: 175px;
    max-width: 100%;
    border-radius: 5px;
  }

  .next, .prev {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: auto 0;
    top: 0;
    bottom: 0;
    border-radius: 30px;
    font-size: 1.5em;
    background-color: white;
    cursor: pointer;
    border: none;
    box-shadow: 0 4px 4px $grey-light;

    @include desktop-large-up {
      width: 36px;
      height: 36px;
      font-size: 1.25em;
    }
  }

  .next {
    right: 0;

    @include desktop-large-up {
      right: -20px;
    }
  }

  .prev {
    left: 0;

    @include desktop-large-up {
      left: -20px;
    }
  }
}
</style>
