<template>
  <b-container ref="idResult" id="id-result">
    <spinner-indeterminate text="Loading possible species..." v-if="isLoading"></spinner-indeterminate>
    <div v-else-if="resultCount > 0" class="mt-5">
      <b-row align-v="baseline" class="result-header mb-2">
        <b-col>
          <h3>Found {{ resultCount }} results.</h3>
        </b-col>
        <b-col order="end">
          <b-form inline>
            <b-input-group size="sm">
              <!--suppress XmlInvalidId -->
              <label class="mr-sm-2" for="result-sortBy">Sort by</label>
              <b-form-select v-model="sortBy" :options="sortOptions" class="mb-sm-0" id="result-sortBy">
              </b-form-select>
              <b-input-group-append>
                <b-button variant="outline-secondary" :pressed.sync="sortOrder">
                  <font-awesome-icon v-if="sortOrder" icon="sort-up"></font-awesome-icon>
                  <font-awesome-icon v-if="!sortOrder" icon="sort-down"></font-awesome-icon>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form>
        </b-col>
      </b-row>
      <b-alert :show="apiMessage !== ''">{{ apiMessage }}</b-alert>

      <ul>
        <li v-for="(species, index) in orderedResults" :key="index">
          <b-row>
            <b-col align-self="center">
              <b-row>
                <b-col>
                  <router-link :to="'/species?genus=' + species.genus + '&species=' + species.species"
                               target="_blank">
                    <h4>{{ species.genus + ' ' + species.species + ' (' + species.author + ', ' + species.year + ')' }}</h4>
                  </router-link>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="4" xl="3">
                  <p>Higher Taxa:</p>
                </b-col>
                <b-col cols="12" md="11" lg="8">
                  <p>{{ truncate(species.taxa) }}</p>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="4" xl="3">
                  <p>Common Names:</p>
                </b-col>
                <b-col cols="12" md="11" lg="8">
                  <p>{{ truncate(species.taxa) }}</p>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="12" lg="4" align-self="center">
<!--              <light-gallery v-if="item.images != null && item.images.length > 0" v-bind:images="item.images"-->
<!--                             :key="item.id"></light-gallery>-->
            </b-col>
          </b-row>
        </li>
      </ul>
    </div>
  </b-container>
</template>

<script>
import SpinnerIndeterminate from '../base/SpinnerIndeterminate'
import LightGallery from '../base/LightGallery'
import { mapGetters, mapState } from 'vuex'

const orderBy = require('lodash/orderBy')

export default {
  name: 'id-result',
  components: {
    LightGallery,
    SpinnerIndeterminate
  },
  data () {
    return {
      sortOptions: [
        {
          text: 'Relevance',
          value: 'score'
        },
        {
          text: 'Species',
          value: 'genus'
        },
        {
          text: 'Author',
          value: 'author'
        },
        {
          text: 'Year',
          value: 'year'
        }
      ],
      sortBy: 'score',
      sortOrder: false
    }
  },
  computed: {
    ...mapState('identification', ['isLoading', 'results', 'apiMessage', 'resultCount']),
    orderedResults: function () {
      const sOrder = this.sortOrder ? 'asc' : 'desc'
      return orderBy(this.results, [this.sortBy], [sOrder])
    }
  },
  methods: {
    truncate (text) {
      if (text.length <= 110) {
        return text
      }
      return text.substr(0, 110) + '\u2026'
    }
  },
  watch: {
    isLoading(newState, oldState) {
      if (!oldState && newState) {
        this.$refs.idResult.scrollIntoView(true)
      }
    }
  }
}
</script>

<style type="scss" lang="scss">

#id-result {
  width: 100%;
  margin: 0 auto;

  .result-header {
    form {
      justify-content: flex-end;
    }
  }

  ul {
    padding: 0;
    list-style: none;

    li {
      padding: 3rem;
      margin-bottom: 3rem;
      border: 1px solid $grey-disabled;
      border-radius: 10px;
      box-shadow: 4px 10px 15px $grey-disabled;

      a {
        text-decoration: none;
      }

      h4 {
        font-weight: 400;
        color: $color-accent;
        text-transform: unset;

        &:hover {
          color: $color-accent-hover;
          text-decoration: underline;
        }
      }
    }
  }

  .light-gallery {
    height: 200px;
    margin-top: 1em;
    @include desktop-large-up {
      margin-top: 0;
    }
  }

  #spinner-indeterminate {
    margin: 150px 0 100px 0;
  }
}
</style>
