module.exports = [
  {
    name: 'uniform',
    selected: false,
    img: require('@/assets/visual_solid-pattern.jpg')
  },
  {
    name: 'banded',
    selected: false,
    img: require('@/assets/visual_ring-pattern.jpg')
  },
  {
    name: 'blotched',
    selected: false,
    img: require('@/assets/visual_blotched-pattern.jpg')
  },
  {
    name: 'spotted',
    selected: false,
    img: require('@/assets/visual_spotted-pattern.jpg')
  },
  {
    name: 'striped',
    selected: false,
    img: require('@/assets/visual_stripe-pattern.jpg')
  }
]
