<template>
  <b-container id="identification" fluid>
    <b-row id="id-title">
      <b-col cols="12" lg="5" offset-xl="1">
        <h1>Identify a species<br> by</h1>
      </b-col>
      <b-col class="pt-md-3" cols="12" lg="5">
        <p>
          Don't know the name of a species? Try finding it by
          providing the habitat, color, pattern and size.
        </p>
        <p>
          Start by clicking an area on the map to specify the reptiles habitat.
          Adjust the radius in which reptiles will be searched by adjusting the slider.
        </p>
      </b-col>
    </b-row>

    <b-row id="id-input">
      <b-col cols="12" lg="7" offset-xl="1" xl="6">
        <h4>Geography</h4>
        <spatial-map :height="'620px'" :options="true"></spatial-map>
      </b-col>
      <b-col id="morphology" cols="12" lg="5">
        <h4>Morphology</h4>
        <b-row>
          <b-col class="column" cols="12" lg="12" md="6">
            <p>Colors</p>
            <span class="text-muted"> 4 max</span>
            <div class="list colors">
              <div v-for="(color, index) in colors" :key="index"
                   v-b-tooltip.hover="{
                      title: color.name,
                      delay: {
                        show: 500,
                        hide: 50
                      }}"
                   :class="['list-square', color.selected ? 'selected' : '']"
                   :style="'background:' + color.color"
                   @click="selectColor(color)"
              ></div>
            </div>
          </b-col>
          <b-col class="column" cols="12" lg="12" md="6">
            <p>Pattern</p>
            <span class="text-muted"> 1 max</span>
            <div class="list">
              <img v-for="(pattern, index) in pattern" :key="index" v-b-tooltip.hover="{
                      title: pattern.name,
                      delay: {
                        show: 750,
                        hide: 50
                      }}"
                   :alt="pattern.name"
                   :class="['list-square', pattern.selected ? 'selected' : '']"
                   :src="pattern.img"
                   class="pattern-image"
                   @click="selectPattern(pattern)">
              <span>{{ pattern.name }}</span>
            </div>
          </b-col>
          <b-col class="mt-md-4 column" cols="12" lg="7" sm="6">
            <p>Size</p>
            <b-form-group>
              <b-form-radio-group
                id="btn-radios-2"
                v-model="selectedSize"
                :options="sizes"
                size="lg"
                stacked
              ></b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col align-self="end" class="text-lg-center" cols="12" sm="4">
            <b-button :disabled="!isValid" size="lg" type="submit" variant="light" @click="submit">Identify</b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <id-result></id-result>
  </b-container>
</template>

<script>
import SpatialMap from '@/components/base/SpatialMap'
import idResult from '@/components/id/Result'
import colors from '@/assets/morphology/colors'
import pattern from '@/assets/morphology/pattern'
import sizes from '@/assets/morphology/sizes'
import { cloneDeep, isEmpty } from 'lodash'

export default {
  name: 'identification',
  components: {
    idResult,
    SpatialMap
  },
  data () {
    return {
      colors: cloneDeep(colors),
      pattern: cloneDeep(pattern),
      sizes: cloneDeep(sizes),
      selectedSize: 'tiny',
    }
  },
  computed: {
    selectedColors () {
      return this.calcSelected(this.colors)
    },
    selectedPattern () {
      return this.calcSelected(this.pattern)
    },
    isValid () {
      const iColors = this.selectedColors.length
      const iPattern = this.selectedPattern.length
      return !!(iColors < 5 && iColors > 0 && iPattern === 1 && !isEmpty(this.$store.state.spatialMap.geometry.coordinates))
    },
  },
  methods: {
    calcSelected (collection) {
      return collection.filter(item => {
        return item.selected
      })
    },
    selectColor (color) {
      let i = this.selectedColors.length
      if (i < 4) {
        color.selected = !color.selected
      } else if (color.selected) {
        color.selected = false
      }
    },
    selectPattern (pattern) {
      let i = this.selectedPattern.length
      if (i < 1) {
        pattern.selected = !pattern.selected
      } else if (pattern.selected) {
        pattern.selected = false
      }
    },
    submit () {
      const colors = this.selectedColors.map(color => color.name)
      const pattern = this.selectedColors.map(pattern => pattern.name)

      let idContext = {
        colors: colors,
        pattern: pattern,
        geometry: this.$store.state.spatialMap.geometry,
        size: this.selectedSize
      }
      this.$store.dispatch('identification/search', idContext)
    }
  }
}
</script>
<style lang="scss">
#identification {
  padding: 0 2rem 0 3rem;

  @include desktop-huge-up {
    padding: 0 15px;
  }

  #id-title {
    margin: 0 0 4rem 0;

    p {
      @include desktop-huge-up {
        padding-left: 15px;
      }
    }
  }

  #id-input {
    @include desktop-huge-up {
      padding: 0;
    }
  }

  #spatial-map {
    margin: 2rem 0;

    @include desktop-large-up {
      margin: 0 5% 0 0;
    }

    .vue2leaflet-map {
      @include desktop-large-down {
        height: 450px !important;
      }
    }
  }

  #morphology {
    @include desktop-large-up {
      max-width: 500px;
    }

    .row {
      & > div:not(:first-child) {
        @include desktop-large-up {
          margin-top: 2rem;
        }
      }
    }

    .column:not(:first-child) {
      margin-top: 2rem;

      @include tablet-up {
        margin: 0;
      }
    }

    p {
      display: inline-block;
      position: relative;
      font-size: 1.25em;
      font-weight: 400;
      text-transform: uppercase;

      span {
        position: absolute;
        bottom: 14px;
        right: -40px;
        padding-left: 0.5rem;
        font-size: 1em;
        color: $grey-disabled;
        text-transform: none;
      }
    }
  }

  .list {
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;

    &.colors {
      max-width: 450px;

      @include desktop-large-up {
        max-width: 400px;
      }
      @include desktop-huge-up {
        max-width: 450px;
      }
    }

    .list-square {
      width: 60px;
      height: 55px;
      margin: 0 10px 10px 0;
      border: 2px solid transparent;
      background-color: $grey-background;

      &.selected {
        border: 3px solid $color-accent !important;
      }

      &:last-child {
        border: 1px solid $black;
        margin-right: 0;
      }

      @include desktop-huge-up {
        width: 75px;
        height: 70px;
        margin: 0 12px 12px 0;
      }
      @include tablet-only {
        width: 75px;
        height: 60px;
        margin: 0 12px 12px 0;
      }
    }
  }

  button[type="submit"] {
    color: $black;
    font-weight: 400;
    width: 100px;
    height: 100px;
    border-radius: 100px;
  }

  fieldset {
    margin-bottom: 0;

    .custom-control {
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
</style>
