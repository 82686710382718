module.exports =
  [
    {
      name: 'brown',
      color: 'saddlebrown',
      selected: false
    },
    {
      name: 'lightbrown',
      color: '#d69b5e',
      selected: false
    },
    {
      name: 'darkbrown',
      color: '#795548',
      selected: false
    },
    {
      name: 'green',
      color: '#4CAF50',
      selected: false
    },
    {
      name: 'black',
      color: '#181825',
      selected: false
    },
    {
      name: 'grey',
      color: '#9E9E9E',
      selected: false
    },
    {
      name: 'red',
      color: '#F44336',
      selected: false
    },
    {
      name: 'yellow',
      color: '#FFC107',
      selected: false
    },
    {
      name: 'beige',
      color: 'beige',
      selected: false
    },
    {
      name: 'unknown',
      color: 'white',
      selected: false
    }
  ]
