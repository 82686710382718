var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"id":"identification","fluid":""}},[_c('b-row',{attrs:{"id":"id-title"}},[_c('b-col',{attrs:{"cols":"12","lg":"5","offset-xl":"1"}},[_c('h1',[_vm._v("Identify a species"),_c('br'),_vm._v(" by")])]),_c('b-col',{staticClass:"pt-md-3",attrs:{"cols":"12","lg":"5"}},[_c('p',[_vm._v(" Don't know the name of a species? Try finding it by providing the habitat, color, pattern and size. ")]),_c('p',[_vm._v(" Start by clicking an area on the map to specify the reptiles habitat. Adjust the radius in which reptiles will be searched by adjusting the slider. ")])])],1),_c('b-row',{attrs:{"id":"id-input"}},[_c('b-col',{attrs:{"cols":"12","lg":"7","offset-xl":"1","xl":"6"}},[_c('h4',[_vm._v("Geography")]),_c('spatial-map',{attrs:{"height":'620px',"options":true}})],1),_c('b-col',{attrs:{"id":"morphology","cols":"12","lg":"5"}},[_c('h4',[_vm._v("Morphology")]),_c('b-row',[_c('b-col',{staticClass:"column",attrs:{"cols":"12","lg":"12","md":"6"}},[_c('p',[_vm._v("Colors")]),_c('span',{staticClass:"text-muted"},[_vm._v(" 4 max")]),_c('div',{staticClass:"list colors"},_vm._l((_vm.colors),function(color,index){return _c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: color.name,
                    delay: {
                      show: 500,
                      hide: 50
                    }}),expression:"{\n                    title: color.name,\n                    delay: {\n                      show: 500,\n                      hide: 50\n                    }}",modifiers:{"hover":true}}],key:index,class:['list-square', color.selected ? 'selected' : ''],style:('background:' + color.color),on:{"click":function($event){return _vm.selectColor(color)}}})}),0)]),_c('b-col',{staticClass:"column",attrs:{"cols":"12","lg":"12","md":"6"}},[_c('p',[_vm._v("Pattern")]),_c('span',{staticClass:"text-muted"},[_vm._v(" 1 max")]),_c('div',{staticClass:"list"},[_vm._l((_vm.pattern),function(pattern,index){return _c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({
                    title: pattern.name,
                    delay: {
                      show: 750,
                      hide: 50
                    }}),expression:"{\n                    title: pattern.name,\n                    delay: {\n                      show: 750,\n                      hide: 50\n                    }}",modifiers:{"hover":true}}],key:index,staticClass:"pattern-image",class:['list-square', pattern.selected ? 'selected' : ''],attrs:{"alt":pattern.name,"src":pattern.img},on:{"click":function($event){return _vm.selectPattern(pattern)}}})}),_c('span',[_vm._v(_vm._s(_vm.pattern.name))])],2)]),_c('b-col',{staticClass:"mt-md-4 column",attrs:{"cols":"12","lg":"7","sm":"6"}},[_c('p',[_vm._v("Size")]),_c('b-form-group',[_c('b-form-radio-group',{attrs:{"id":"btn-radios-2","options":_vm.sizes,"size":"lg","stacked":""},model:{value:(_vm.selectedSize),callback:function ($$v) {_vm.selectedSize=$$v},expression:"selectedSize"}})],1)],1),_c('b-col',{staticClass:"text-lg-center",attrs:{"align-self":"end","cols":"12","sm":"4"}},[_c('b-button',{attrs:{"disabled":!_vm.isValid,"size":"lg","type":"submit","variant":"light"},on:{"click":_vm.submit}},[_vm._v("Identify")])],1)],1)],1)],1),_c('id-result')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }