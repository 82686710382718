module.exports = [
  {
    value: 'tiny',
    text: '< 300mm',
    sMin: '1mm',
    sMax: '300mm'
  },
  {
    value: 'small',
    text: '301mm-600mm',
    sMin: '301mm',
    sMax: '600mm'
  },
  {
    value: 'medium',
    text: '601mm-900mm',
    sMin: '601mm',
    sMax: '900mm'
  },
  {
    value: 'large',
    text: '> 900mm',
    sMin: '900mm',
    sMax: '900mm+'
  }
]
